<template>
  <div class="max-w-2xl sm:max-w-md vision-board">
    <div class="uppercase font-semibold ">Your Vision Board</div>
    <div class="vision-board-inner border-2" v-bind:class="{'bg-gray-100 border': list.length !== 0, 'p-5': list.length === 0}">
      <Text v-if="get(list, 'length') === 0" size="sm" weight="semibold" :content="defaultText" custom-class="my-3 w-5/6" color="gray-400"/>
      <div class="w-full grid grid-cols-3 p-3 gap-x-2 list-outer" v-else>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="board-item border p-1 w-full"
        >
          <img :src="getSchoolLogo(item.images)" class="w-full h-full object-cover"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Text from '../../atoms/Text/Text'
import {get} from 'lodash'
export default {
  components: {
    Text,
  },
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {
      defaultText: 'Build your college list today. As you select your schools they will populate into your vision board here. <br />Let’s get that money!',
      get,
    };
  },

  methods:{
    getSchoolLogo(images) {
      if (images && images[0] && images[0].url) {
        return images[0].url.replace(
          'https://turboaid.s3-us-west-2.amazonaws.com/',
          'https://faid-logos.s3.us-west-2.amazonaws.com/'
        )
      } else {
        return 'https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-icon.png'
      }
    }
  }
};
</script>

<style scoped>
.board-item {
  width: 74px;
  height: 74px;
}
.vision-board {
  width: 400px;
}

@media (max-width: 746px) {
  .vision-board {
    width: 100%;
  }
}
.vision-board-inner {
  min-height: 280px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  font-size: 15px;
  min-height: 310px;
  max-height: 310px;
}
.list-outer {
  height: 310px;
  overflow-y: scroll;
}
</style>
