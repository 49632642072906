<template>
  <div class="flip-card max-w-xs mx-auto border rounded-md relative">
    <div class="flip-card-inner">
      <div class="flip-card-front bg-white rounded-md">
        <div class="px-6 pt-6 w-full flex flex-col justify-start items-start">
          <div class="flex w-full h-48 bg-gray-100 p-3">
            <Image :src="getSchoolLogo(school?.logo?.url)" customClass="m-auto" :width="school?.logo?.width || 'full'" :height="school?.logo?.height || 'full'" />
          </div>
          <Text :content="school?.name || ''" color="gray-900" bold="semibold" customClass="my-3" align="left" />
          <div class="cost-info relative w-full text-left" v-bind:class="{ 'no-cost': !locked && isNoCost }">
            <Text content="Upfront Cost/yr:" color="gray-500" size="sm" customClass="mb-1 relative" />
            <Badge v-if="locked" label="Locked" iconName="LockClosedIcon" bgColor="white" :isRounded="false" :isLeft="true" class="border" textColor="gray-500" :py="1" :px="2" />
            <Text v-else :content="getCalculatedData(school, 'outOfPocket', school?.applicationType === 'UC' || school?.applicationType === 'CAL_STATE' ? 'atHome' : 'onCampus', isInSchool)" color="primary" weight="bold" customClass="relative" />
          </div>
        </div>
      </div>
      <div class="flip-card-back bg-primary rounded-md p-6 mx-auto relative">
        <div class="lock-payment-card" v-if="locked"></div>
        <table class="divide-y divide-white w-full m-auto" v-bind:class="{ 'locked-table': locked, 'only-campus-table': !homeValuesShow }">
          <thead>
            <tr class="divide-x divide-white py-2">
              <th class="w-1/2"></th>
              <th class="text-center px-2 py-2">
                <Icon name="AcademicCapIcon" size="5" color="white" class="m-auto" />
                <Text size="xs" weight="semibold" color="white" content="Living On <br />Campus" />
              </th>
              <th class="text-center px-2 py-2" v-if="homeValuesShow">
                <Icon name="HomeIcon" size="5" color="white" class="m-auto" />
                <Text size="xs" weight="semibold" color="white" content="Living at Home" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="divide-x divide-white text-xs h-14">
              <td class="py-2 text-white text-left">Original<br />Cost</td>
              <td class="py-2 text-white" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "cost", "onCampus", isInSchool) }}</td>
              <td v-if="homeValuesShow" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "cost", "atHome", isInSchool) }}</td>
            </tr>
            <tr class="divide-x divide-white text-xs h-14">
              <td class="py-2 text-white text-left">Grants</td>
              <td class="py-2 text-white" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "grants", "onCampus", isInSchool) }}</td>
              <td v-if="homeValuesShow" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "grants", "atHome", isInSchool) }}</td>
            </tr>
            <tr class="divide-x divide-white text-xs h-14">
              <td class="py-2 text-white text-left">Loans</td>
              <td class="py-2 text-white" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "loans", "onCampus", isInSchool) }}</td>
              <td v-if="homeValuesShow" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "loans", "atHome", isInSchool) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="divide-x divide-white text-xs h-14">
              <td class="py-2 font-semibold text-left">Upfront<br />Cost/yr</td>
              <td class="py-2 text-white" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "outOfPocket", "onCampus", isInSchool) }}</td>
              <td v-if="homeValuesShow" v-bind:class="{ 'text-blurry': locked }">{{ locked ? "$0000" : getCalculatedData(school, "outOfPocket", "atHome", isInSchool) }}</td>
            </tr>
          </tfoot>
        </table>
        <Icon name="LockClosedIcon" size="6" color="white" class="absolute" style="top: 40%; right: 123px; transform: translate(-50%, -50%);" v-if="locked" />
      </div>
    </div>
    <Button content="Remove from List" :click="() => $emit('onAddToList')" :is-loading="isLoading && get(selectedSchool, 'id') === get(school, 'id')" class="absolute bottom-2 left-2 right-2 text-yellow-500 bg-white border border-yellow-500" v-if="inFinalListArray" />
    <Button :click="() => $emit('onAddToList')" :is-loading="isLoading && get(selectedSchool, 'id') === get(school, 'id')" :content="`${locked ? 'Upgrade to Unlock' : 'Add to List'}`" class="absolute bottom-2 left-2 right-2 text-primary bg-white border border-primary" v-else />
  </div>
</template>

<script>
import Image from "../../../atoms/Image/Image.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";
import Icon from "../../../atoms/Icons/Icons";
import { _ } from "vue-underscore";
import numeral from "numeral";
import { get } from "lodash";

export default {
  components: {
    Image,
    Text,
    Button,
    Badge,
    Icon,
  },
  data() {
    return {
      inFinalListArray: this.isInList,
      get,
    };
  },
  watch: {
    isInList: function (value) {
      this.inFinalListArray = value;
    },
  },
  methods: {
    getSchoolLogo(url) {
      if(url) {
        return url.replace(
          'https://turboaid.s3-us-west-2.amazonaws.com/',
          'https://faid-logos.s3.us-west-2.amazonaws.com/'
        )
      }
      return 'https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-icon.png'
    }
  },
  computed: {
    homeValuesShow() {
      const school = this.school;
      const app = this.state.currentFinApp;
      if (app?.schoolList && school) {
        const matchSchool = _.find(app["schoolList"], (item) => {
          return item?.school?.id === school.id && item.isInStateSchool;
        });
        if (matchSchool?.id) {
          return school?.statusSchoolType === "STATE";
        } else {
          return false;
        }
      }
      return false;
    },
    isNoCost() {
      const school = this.school;
      if (school && school.calculationData) {
        if (_.isArray(school.calculationData["onCampus"]["outOfPocket"])) {
          return false;
        } else {
          const value = school.calculationData["onCampus"]["outOfPocket"] ? numeral(school.calculationData["onCampus"]["outOfPocket"]).format("$0,0") : numeral(0).format("$0,0");
          if (value === "$0") return true;
          else return false;
        }
      } else {
        return false;
      }
    },
  },
  props: {
    school: {
      type: Object,
      default: () => {},
    },
    amount: {
      type: String,
      default: "",
    },
    status: {
      type: Boolean,
      default: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    getCalculatedData: {
      type: Function,
      default: () => "",
    },
    isInList: {
      type: Boolean,
      default: false,
    },
    selectedSchool: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isInSchool: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 395px;
  perspective: 1000px;
  overflow: hidden;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 395px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #5f646e;
  color: white;
  transform: rotateY(180deg);
  height: 470px;
}

.text-blurry {
  color: transparent !important;
  text-shadow: 0 0 10px hsl(0deg 0% 100% / 50%);
}

.cost-info.no-cost:before {
  position: absolute;
  content: "";
  display: block;
  top: -5px;
  bottom: -5px;
  left: -25px;
  right: -25px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #dee1e8;
  background-image: url("../../../../assets/public/images/no-cost-shadow.png");
  background-repeat: no-repeat;
  background-position: -2px -6px;
  background-size: 150px;
  background-color: #f9f9f9;
}

.cost-info.no-cost:after {
  position: absolute;
  content: "All upfront costs are covered by grants & loans.";
  right: 0px;
  font-size: 10px;
  line-height: 1.5;
  color: #868c97;
  max-width: 115px;
  top: 0px;
  letter-spacing: normal;
}

.lock-payment-card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}

.flip-card-back .table.only-campus-table {
  margin: auto;
  width: 74%;
}

/* .locked-table:before {
  content: "\f023";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 120px;
  font-family: "Fontawesome";
  font-size: 40px;
} */

.only-campus-table.locked-table:before {
  right: 90px;
}
</style>
